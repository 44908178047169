import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

function Footer() {
    return (
    <div className="absolute inset-x-0 bottom-0 h-28">
        <footer className="grid md:grid-cols-2 container h-full mx-auto">
        <div className="flex h-full items-center md:justify-start justify-center">
                &copy; 2020 - {new Date().getFullYear()} Wilkio Limited
            </div>
            <div className="flex h-full md:justify-end justify-center">
                <div className="h-100 my-auto mr-8">
                    <StaticImage
                    src="../images/control4-dealer.png"
                    alt="CEDIA® Member"
                    placeholder="none"
                    height={60}
                    />
                </div>
                <div className="h-100 my-auto">
                    <StaticImage
                    src="../images/cedia-member.png"
                    alt="CEDIA® Member"
                    placeholder="none"
                    height={24}
                    />
                </div>
            </div>
        </footer>
    </div>
    )
}

export default Footer;