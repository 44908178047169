import PropTypes from "prop-types"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"

function Header({ siteTitle }) {

  return (
    <div className="flex flex-col lg:flex-row  justify-center items-center h-1/6">
      <div>
      <span className="text-6xl">👋🏼 Hello, we’re</span>
      </div>
      <div className="mt-6 lg:mt-0" style={{ maxWidth: `400px`, paddingLeft: `1rem`}}>
        <StaticImage
        src="../images/wilkio-red.svg"
        alt={siteTitle}
        placeholder="none"
        />
      </div>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
